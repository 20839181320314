import React, { Component } from 'react'

/* Importando Iconos FontAwesome*/
import { FaChevronLeft } from 'react-icons/fa'
import { FaChevronRight } from 'react-icons/fa'
import { FaCircle } from 'react-icons/fa'

/* Importando las clases */
import './SliderImage.css'

/* Importando Imagenes */
import Img1 from './pics/1.jpg'
import Img2 from './pics/2.jpg'
import ImgDigi from './pics/digi.jpeg'

/* Importando Videos */
import Vid1 from './video/TecladoMac.mp4'
import Vid2 from './video/Idea.mp4'
import Vid3 from './video/Reunion.mp4'

/* Creacion de un array con las informaciones de cada slide que sera visualizado */
let sliderArray = [
    { id: 1, title: 'Slide1', backGround: <Imagen src={Img1} />, primaryText: 'Desenvolvemos a solução ideal para você e sua empresa!', secondaryText: 'Trabalhamos focados em entregar um produto com qualidade e desempenho que alcance o objetivo desejado pelo cliente.', button: true, urlButton: 'coci', textButton: 'Saiba como' },
    { id: 2, title: 'Slide2', backGround: <VideoComp src={Vid1} />, primaryText: 'Focamos ao máximo no objetivo do projeto!', secondaryText: 'Com as melhores técnicas, ferramentas e a melhor equipe, progredimos rumo ao objetivo final dos projetos com foco total na solução ideal para nossos clientes.', button: true, urlButton: 'coci', textButton:'Conheça-nos'},
    { id: 3, title: 'Slide3', backGround: <VideoComp src={Vid2} />, primaryText: 'Tem alguma ideia, mas não sabe como coloca-lá em prática?', secondaryText: 'Damos todo o suporte necessário para tirar sua ideia do papel e transforma-lá em um produto de qualidade para atingir o objetivo desejado.',button: true, urlButton: 'coci', textButton: 'Saiba como' },
    { id: 4, title: 'Slide4', backGround: <Imagen src={Img2} />, primaryText: '3LStore', secondaryText: 'A loja virtual que seu negócio tanto precisa está aqui.', button: true, urlButton: 'coci', textButton: 'Saiba mais' },
    { id: 5, title: 'Slide5', backGround: <VideoComp src={Vid3} />, primaryText: 'Elaboramos e executamos estratégias específicas para cada projeto!', secondaryText: 'Visando obter máximo desempenho e qualidade dentro dos prazos estipulados.' },
    { id: 6, title: 'Slide6', backGround: <Imagen src={ImgDigi} />, primaryText: 'Alavanque seu negócio na internet!', secondaryText: 'Um pacote de serviços para te ajudar...', button: true, urlButton: 'coci', textButton: 'Saiba como' }
]

/* Componente encargado de cargar las imagenes */
function Imagen ({ src }) {
    return (
        <img 
            src={ src }
            alt='slide-img'
            className='slideImage'
        />
    )
}

/* Componente encargado de cargar las imagenes */
function VideoComp ({ src }) {
    return (
        <video autoPlay loop>
            <source
                src={ src }
                type='video/mp4'
            />
        </video>
    )
}

class SliderImage extends Component {
    
    /* Creamos el estado para poder mover el slide */
    state = {
        positionX: 0
    }

    /* Intervalo de tiempo para poder avanzar el slider */
    componentDidMount () {
        setInterval(() => {
            this.removePositionX()
        }, 10000)
    }

    /* Funcion para aumentar el valor de X */
    addPositionX = () => {
        if( this.state.positionX === 0) {
            this.setState({
                positionX: (-100 * (sliderArray.length -1))
            })
        } else {
            this.setState({
                positionX: this.state.positionX + 100
            })
        }
    }

    /* Funcion para disminuir el valor de X */
    removePositionX = () => {
        if( this.state.positionX === (-100 * (sliderArray.length -1))) {
            this.setState({
                positionX: 0
            })
        } else {
            this.setState({
                positionX: this.state.positionX - 100
            })
        }
    }

    render() {
        return(
            <div className='sliderImage'>
                {
                    /* Iterando con el array para cada slider */
                    sliderArray.map((slide, index) => {
                        return (
                            <div key={ slide.id } className='slide' style={{ transform: `translateX(${ this.state.positionX }%)`}}>
                                { slide.backGround }
                                <div className='sliderContent'>
                                    <div className='primaryText'>
                                        { slide.primaryText }
                                    </div>
                                    <div className='secondaryText'>
                                        { slide.secondaryText }
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
                <div className="slideControlsContainer">
                    <div className="slideControlsContent">
                        <button
                            id="goLeft"
                            onClick={ this.addPositionX }
                        >
                            <FaChevronLeft />
                        </button>
                        <span>
                            <FaCircle />
                        </span>
                        <button
                            id="goRight"
                            onClick={ this.removePositionX }
                        >
                            <FaChevronRight />
                        </button>
                    </div>
                </div>
            </div>
        )
    }
}

export default SliderImage