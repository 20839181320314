import React, { Component } from 'react'
import { Link, NavLink } from 'react-router-dom'

/* Importando Iconos FontAwesome*/
import { FaLanguage } from 'react-icons/fa'
import { FaRegUser } from 'react-icons/fa'

/* Importando los estilos */
import './navBar.css'

/* Importando imnagenes */
import Logo from './pic/logo.png'

/* Componente encargado de cargar las imagenes */
function Imagen ({ src }) {
    return (
        <img 
            src={ src }
            alt='Logo 3Layers'
            className='navLogo'
        />
    )
}

class navBar extends Component {
    render() {
        return (
            <nav className='principalNav'>
                <div className='containerNav'>
                    <div className='loginBar'>
                        <div className='language'>
                            <FaLanguage className='languageIcon' />
                            <div className='contentSelect'>
                                <select className='languageSelect'>
                                    <option>Português</option>
                                    <option>Espanhol</option>
                                    <option>Inglês</option>
                                </select>
                                <i></i>
                            </div>
                        </div>
                        <div className='separator'>|</div>
                        <div className='login'>
                            <FaRegUser className='userIcon' />
                            <div className='userText'>Entrar</div>
                        </div>
                    </div>
                    <div className='menuBar'>
                        <div className='logo'>
                            <Link to='/'>
                                <Imagen src={ Logo } />
                            </Link>
                        </div>
                        <div className='menu'>
                            <ul>
                                <li><NavLink activeClassName='active' to="/">HOME</NavLink></li>
                                <li><NavLink activeClassName='active' to='/sobre'>SOBRE</NavLink></li>
                                <li><NavLink activeClassName='active' to='/servicos'>SERVIÇOS</NavLink></li>
                                <li><NavLink activeClassName='active' to='/projetos'>PROJETOS</NavLink></li>
                                <li><NavLink activeClassName='active' to='/contato'>CONTATO</NavLink></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </nav>
        )
    }
}

export default navBar