import React from 'react'

/* Importando librerias */
import { Route, Switch } from 'react-router-dom'

/* Importando as paginas */
import Home from './pages/Home'
import Sobre from './pages/Sobre'
import Servicos from './pages/servicos'
import Projetos from './pages/projetos'
import Contato from './pages/contato'
import ErrorPage from './pages/ErrorPage'

/* Importando Componentes */
import NavBar from './componentes/navBar'

function Routes() {
    return(
        <>
            <NavBar />
            <Switch>
                <Route exact path="/" component={ Home } />
                <Route exact path="/sobre" component={ Sobre } />
                <Route exact path="/servicos" component={ Servicos } />
                <Route exact path="/projetos" component={ Projetos } />
                <Route exact path="/Contato" component={ Contato } />
                <Route component={ ErrorPage } />
            </Switch>
        </>
    )
}

export default Routes