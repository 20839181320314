import React, { Component, useState } from 'react'
import Axios from 'axios'

import './contato.css'

import Cubo from  './pics/cubo.png'

/* Componente encargado de cargar las imagenes */
function Imagen ({ src }) {
    return (
        <img 
            src={ src }
            alt='servicos'
            className='contactLogo'
        />
    )
}

class Contato extends Component {
    constructor(props) {
        super(props)
        this.state = {
            name: '',
            email: '',
            asunto: '',
            message: ''
        }
    }

    render() {
        return(
            <section>
                <div className='contactImg'>   
                    <h1>Fale com a 3Layers</h1>
                </div>                
                <div className='contactContainer'>
                    <div className='contactLeft'>
                        <Imagen src={ Cubo } />
                    </div>
                    <div className='contactRight'>
                        <form id='contact-form' onSubmit={ this.handleSubmit.bind(this) } method='POST'>
                            <div className='contactGrpControls'>
                                <label htmlFor='name'>Nome</label>
                                <input type='text' value={ this.state.name } onChange={ this.onNameChange.bind(this) } />
                            </div>
                            <div className='contactGrpControls'>
                                <label htmlFor='email'>E-Mail</label>
                                <input type='email' value={ this.state.email } onChange={ this.onEmailChange.bind(this) } />
                            </div>
                            <div className='contactGrpControls'>
                                <label htmlFor='asunto'>Assunto</label>
                                <input type='text' value={ this.state.asunto } onChange={ this.onAsuntoChange.bind(this) } />
                            </div>
                            <div className='contactGrpControls'>
                                <label htmlFor='message'>Mensagem</label>
                                <textarea rows='5' value={ this.state.message } onChange={ this.onMessageChange.bind(this) } />
                            </div>
                            <button type='submit'>Enviar</button>
                        </form>
                    </div>
                </div>
            </section>
        )
    }

    onNameChange(event) {
        this.setState({ name: event.target.value })
    }

    onEmailChange(event) {
        this.setState({ email: event.target.value })
    }

    onAsuntoChange(event) {
        this.setState({ asunto: event.target.value })
    }

    onMessageChange(event) {
        this.setState({ message: event.target.value })
    }

    resetForm() {
        this.setState({ name: '', email: '', asunto: '', message: ''})
    }

    handleSubmit(event) {
        event.preventDefault()
        
        Axios({
            method: "POST",
            url: "http://3layerstudio.com/send",
            data: this.state
        }).then((response) => {
            if(response.data.status === 'success') {
                alert("Mensagem enviada")
                this.resetForm()
            } else if(response.data.status === 'fail') {
                alert("Erro: Mensagem não enviada")
            }
        })
    }

}

export default Contato