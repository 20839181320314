import React, { Component } from 'react'

/* Importando el css */
import './sectionPanel.css'

/* Importando imagenes */
import img1 from './pics/panel.svg'

/* Componente encargado de cargar las imagenes */
function Imagen ({ src }) {
    return (
        <img 
            src={ src }
            alt='panel-img'
            className='panelImg'
        />
    )
}

class SectionPanel extends Component {
    render() {
        return(
            <section className='panel'>
                <div className='panelContainer'>
                    <h1>Temos a solução ideal em E-commerce para você!</h1>
                    <p>Com o <b>3LStore</b>, você adquire sua loja virtual com um designer moderno, atrativo e com um painel administrativo totalmente personalizado e preparado para um gerenciamento rápido e completo. Tornando suas vendas online muito mais simples e fácil.</p>
                    <Imagen src={ img1 } />
                        <a href="https://www.3lstore.com">
                        Confira os planos
                        </a>
                </div>
            </section>
        )
    }
}

export default SectionPanel