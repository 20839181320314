import React, { Component } from 'react'
import Bounce from 'react-reveal/Bounce'
import { Link, NavLink } from 'react-router-dom'

/* Importando estilos css */
import './sectionTech.css'

/* Importando imagenes */
import img1 from './pics/buildSite.svg'

/* Componente encargado de cargar las imagenes */
function Imagen ({ src }) {
    return (
        <img 
            src={ src }
            alt='slide-img'
            className='techImg'
        />
    )
}

class sectionTech extends Component {
    render() {
        return(
            <section className='tech'>
                <span className='techBg'></span>
                <div className='techContainer'>
                    <Bounce left cascade>
                        <div className='techLeft'>
                            <Imagen src={ img1 } />
                        </div>
                        <div className='techRight'>
                            <h1>Fazemos a tecnologia trabalhar a seu favor!</h1>
                            <p>
                                Construimos websites, e-commerce, aplicações desktop, plataformas completas para alavancar seu négocio, proporcionando um melhor desempenho para sua empresa ou marca tanto no mundo digital como físico. Temos o plano ideal para você e sua empresa!
                            </p>
                            <NavLink activeClass='active' to='/contato'>
                                Solicite um orçamento
                            </NavLink>
                        </div>
                    </Bounce>
                </div>                
            </section>
        )
    }
}

export default sectionTech