import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom'

/* Importando Home page */
import Routes from './routes'

/* Importando los estilos para poder dejar el sitio Normalizado */
import './index.css'

ReactDOM.render(
    <Router>
        <Routes />
    </Router>
    , document.getElementById('root'));