import React, { Component } from 'react'
import Zoom from 'react-reveal/Zoom'

/* Componentes */
import SliderImage from '../../componentes/sliderImage'
import SectionLayers from '../../componentes/sectionLayers'
import SectionTech from '../../componentes/sectionTech'
import SectionMobile from '../../componentes/sectionMobile'
import SectionPanel from '../../componentes/sectionPanel'
import SectionServices from '../../componentes/sectionServices'
import SliderSmall from '../../componentes/sliderSmall'
/* import SectionTeam from '../../componentes/sectionTeam' <SectionTeam /> */
/* import SectionWork from '../../componentes/sectionWork' <SectionWork /> */
import SectionFooter from '../../componentes/sectionFooter'

class Home extends Component {
    
    render() {
        return(
            <>
                <header>
                    <SliderImage />
                </header>
                <main>
                    <SectionLayers />
                    <SectionTech />
                    <SectionMobile />
                    <Zoom>
                        <SectionPanel />
                    </Zoom>
                    <SliderSmall />
                    <SectionServices />
                </main>
                <SectionFooter />
            </>
        )
    }
}

export default Home