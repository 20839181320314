import React, { Component } from 'react'
import { Link, NavLink } from 'react-router-dom'

/* Importando estilos css */
import './sectionServices.css'

/* Importando Imagenes */
import ImgSoftware from './pics/software.jpg'
import ImgWebDesign from './pics/webdesign.jpg'
import ImgApp from './pics/aplicativos.jpg'
import ImgDesginGraf from './pics/designgraf.jpg'

/* Componente para carga de imagenes */
function Imagen({ src }) {
    return(
        <img
            src={ src }
            alt='imgServices'
            className='servicesImg'
        />
    )
}

class SectionServices extends Component {
    render() {
        return(
            <section className='services'>
                <div className='servicesContainer'>
                    <h1>Nossos Segmentos</h1>
                    <p>Onde empenhamos nossos esforços e garantimos a qualidade e solução para você e sua empresa.</p>
                    <div className='serviceCards'>
                        <div className='serviceCard'>
                            <Imagen src={ ImgSoftware } />
                            <h3>Software</h3>
                            <p>
                                Criamos o software preciso para sua necessidade.
                            </p>
                            <NavLink activeClass='active' to='/servicos'>
                                Saiba mais...
                            </NavLink>
                        </div>
                        <div className='serviceCard'>
                            <Imagen src={ ImgWebDesign } />
                            <h3>Web Design</h3>
                            <p>
                                Criamos layouts 100% personalizados.
                            </p>
                            <NavLink activeClass='active' to='/servicos'>
                                Saiba mais...
                            </NavLink>
                        </div>
                        <div className='serviceCard'>
                            <Imagen src={ ImgApp } />
                            <h3>Aplicativo Móvel</h3>
                            <p>
                                Aplicativos robustos com o melhor desempenho no uso.
                            </p>
                            <NavLink activeClass='active' to='/servicos'>
                                Saiba mais...
                            </NavLink>
                        </div>
                        <div className='serviceCard'>
                            <Imagen src={ ImgDesginGraf } />
                            <h3>Design Gráfico</h3>
                            <p>
                                Criamos folders, flyers, cartões, e outros.
                            </p>
                            <NavLink activeClass='active' to='/servicos'>
                                Saiba mais...
                            </NavLink>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default SectionServices