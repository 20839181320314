import React, { Component } from 'react'

/* Importando Iconos FontAwesome*/
import { FaChevronLeft } from 'react-icons/fa'
import { FaChevronRight } from 'react-icons/fa'

/* Importando estilos css */
import './sliderSmall.css'

/* Importando Imagenes */
import Img1 from './pics/html.png'
import Img2 from './pics/css.png'
import Img3 from './pics/js.png'
import Img4 from './pics/bootstrap.png'
import Img5 from './pics/php.png'
import Img6 from './pics/react.png'
import Img7 from './pics/android.png'
import Img8 from './pics/angular.png'
import Img9 from './pics/materialize.png'
import Img10 from './pics/sass.png'
import Img11 from './pics/vue.png'
import Img12 from './pics/webpack.png'


/* Componente encargado de cargar las imagenes */
function Imagen ({ src }) {
    return (
        <img 
            src={ src }
            alt='slideSm-img'
            className='slideSmImage'
        />
    )
}

/* Creacion de un array con las informaciones de cada slide que sera visualizado */
let sliderSmArray = [
    { id: 1, title: 'Slider1', content: <Imagen src={ Img1 } />},
    { id: 2, title: 'Slider2', content: <Imagen src={ Img2 } />},
    { id: 3, title: 'Slider3', content: <Imagen src={ Img3 } />},
    { id: 4, title: 'Slider4', content: <Imagen src={ Img4 } />},
    { id: 5, title: 'Slider5', content: <Imagen src={ Img5 } />},
    { id: 6, title: 'Slider6', content: <Imagen src={ Img6 } />},
    { id: 7, title: 'Slider7', content: <Imagen src={ Img7 } />},
    { id: 8, title: 'Slider8', content: <Imagen src={ Img8 } />},
    { id: 9, title: 'Slider9', content: <Imagen src={ Img9 } />},
    { id: 10, title: 'Slider10', content: <Imagen src={ Img10 } />},
    { id: 11, title: 'Slider11', content: <Imagen src={ Img11 } />},
    { id: 12, title: 'Slider12', content: <Imagen src={ Img12 } />}
]

class SliderSmall extends Component {
    /* Creamos el estado para poder mover el slide */
    state = {
        positionX: 0
    }

    /* Intervalo de tiempo para poder avanzar el slider */
    componentDidMount () {
        setInterval(() => {
            this.removePositionX()
        }, 10000)
    }

    /* Funcion para aumentar el valor de X */
    addPositionX = () => {
        if( this.state.positionX === 0) {
            this.setState({
                positionX: (-100 * (sliderSmArray.length -1))
            })
        } else {
            this.setState({
                positionX: this.state.positionX + 100
            })
        }
    }

    /* Funcion para disminuir el valor de X */
    removePositionX = () => {
        if( this.state.positionX === (-100 * (sliderSmArray.length -1))) {
            this.setState({
                positionX: 0
            })
        } else {
            this.setState({
                positionX: this.state.positionX - 100
            })
        }
    }

    render() {
        return(
            <section className='sliderSm'>
                <div className='sliderSmContainer'>
                    <h1>Algumas tecnologias usadas em nossos projetos</h1>
                    <ul className='slideSm'>

                        {
                            /* Iterando con el array para cada slider */
                            sliderSmArray.map((item, index) => {
                                return(
                                    <li key={ item.id } className='slideItem' style={{ transform: `translateX(${ this.state.positionX }%)`}}>
                                        { item.content }
                                    </li>
                                )
                            })
                        }
                    
                    </ul>
                    
                    <div className="slideSmControls">
                        <button
                            id="goLeft"
                            onClick={ this.addPositionX }
                        >
                            <FaChevronLeft />
                        </button>
                        <button
                            id="goRight"
                            onClick={ this.removePositionX }
                        >
                            <FaChevronRight />
                        </button>
                    </div>
                    
                </div>
            </section>
        )
    }
}

export default SliderSmall