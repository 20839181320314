import React from 'react'

import './projetos.css'

import Construction from './pics/construction.png'

function Imagen ({ src }) {
    return (
        <img 
            src={ src }
            alt='construção'
            className='contactLogo'
        />
    )
}

function Projetos() {
    return(
        <section>
            <div className='construction'>
                <h1>Seção em Construção...</h1>
                <Imagen src={Construction}/>
            </div>
        </section>
    )
}

export default Projetos