import React, { Component } from 'react';
import PropTypes from "prop-types";
import "./budgetForm.css";
import { findAllByTestId } from '@testing-library/react';

class BudgetForm extends Component {

  render() {
    if (!this.props.show) {
      return null;
    }
    return (
      <div className="modal">
        <h2>Solicitar Orçamento</h2>
        <div className="modalBody">
          <div className="modalBodyInformations">
            <p>Informe os dados abaixo.<br />
              Em breve entraremos em contato.</p>
            <label>
              Nome:
              </label><br />
            <input id="inputName" type="text" name="name" />
            <br />
            <label>
              E-Mail:
              </label><br />
            <input id="inputEmail" type="text" name="email" /><br />
            <label>
              Telefone:
              </label><br />
            <input id="inputTelefone" type="text" name="telefone" /><br />
            <br />
          </div>
        </div>
        <button id="send">Enviar</button>
        <button id="close" onClick={this.showModal} show={this.state.show}>Fechar</button>
      </div>
    );
  }
}
BudgetForm.propTypes = {
  onClose: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired
};
export default BudgetForm