import React, { Component } from 'react'

/* Importando estilos */
import './sectionLayers.css'

class SectionLayers extends Component {

    render() {
        return(
            <section className='layers'>
                <div className='layersContainer'>
                    <div className='leftSide'>
                        <h1>Nossa base é estruturada em três camadas</h1>
                        <p>
                        Com uma metodologia robusta e desenvolvida para obter o maior desempenho e controle do fluxo de trabalho, cobrimos todas as etapas necessárias para que cada projeto seja concluído com sucesso, atingindo ao máximo seu objetivo final.
                        </p>
                    </div>
                    <div className='rightSide'>
                        <div className='cards'>
                            <ul>
                                <li>
                                    <h1>Execução</h1>
                                    <span>Colocamos em prática tudo o que foi desenvolvido anteriormente na análise e estratégia, em um projeto estruturado e preparado para lidar com todas as etapas necessárias.</span>
                                </li>
                                <li>
                                    <h1>Estratégia</h1>
                                    <span>Desenvolvemos um plano de ação conforme os estudos e análises, definindo as ferramentas certas e os melhores caminhos a serem tomados. Garantindo assim o melhor desempenho e qualidade para o projeto.</span>
                                </li>
                                <li>
                                    <h1>Análise</h1>
                                    <span>Estudamos e analisamos as necessidades dos nossos clientes, captando todas as informações que impactarão direta ou indiretamente o projeto, visando aumentar a eficiência e objetividade da solução.</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default SectionLayers