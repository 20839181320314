import React from 'react'

// Importando estilos css
import './sectionFooter.css'

// Importando fontawesome Icons
import { FaMapMarkerAlt } from 'react-icons/fa'
import { FaPhone } from 'react-icons/fa'
import { FaWhatsapp } from 'react-icons/fa'
import { FaEnvelope } from 'react-icons/fa'

/* Importando imnagenes */
import Logo from './pics/logo.png'

/* Componente encargado de cargar las imagenes */
function Imagen ({ src }) {
    return (
        <img 
            src={ src }
            alt='Logo 3Layers'
            className='contactLogo'
        />
    )
}

const SectionFooter = () => {
    return(
        <footer>
            <div className='footerContainer'>
                <div className='footerLogo'>
                    <Imagen src={ Logo } />
                </div>
                <div className='footerContact'>
                    <div className='contactInfo'>
                        <h4>Informações e Contato</h4>
                        <ul>
                            <li><span className='contactIcon'><FaMapMarkerAlt /></span> Rua Barra Velha, 87, Jardim Lancaster V - Foz do Iguaçu - Paraná - Brasil</li>
                            <li><span className='contactIcon'><FaPhone /></span>+55 45 3017-2017</li>
                            <li><span className='contactIcon'><FaWhatsapp /></span>+55 45 9 9133-0333</li>
                            <li><span className='contactIcon'><FaEnvelope /></span>comercial@3layerstudio.com</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className='footerCopy'>
                <p className='copy'>3 Layers Studio. Copyright © 2020 - Todos os direitos reservados.</p>
            </div>
        </footer>
    )
}

export default SectionFooter