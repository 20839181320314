import React from 'react'

/* Importando estilos */
import './sobre.css'

/* Importando Imagenes */
import Cubo from './pics/cubo.png'

/* Componente encargado de cargar las imagenes */
function Imagen ({ src }) {
    return (
        <img 
            src={ src }
            alt='cubo'
        />
    )
}

const Sobre = () => {
    return(
        <section>
            <div className='aboutImg'>
            </div>
            <div className="aboutContainer">
                <div className="aboutNos">
                    <div className="aboutNosImg">
                        <Imagen src={ Cubo } />
                    </div>
                    <div className="aboutNosText">
                        <h1>Nossa História</h1>
                        <p>
                            A 3Layers Studio teve início em 2015, em uma conversa informal entre seus fundadores sobre programação, a partir desta conversa surgiu a ideia de criar uma empresa voltada a tecnologia da informação que proporcionasse algo diferenciado do que já era existente no mercado. Muitos projetos e ideias fizeram parte da jornada da empresa, que em 2017 teve suas estruturas firmadas e começou a desempenhar aquilo que havia sido almejado em seu início, fazer a diferença em cada projeto executado.
                        </p>
                    </div>
                </div>
                <div className="aboutMVV">
                    <div className="aboutColum">
                        <h1>Missão</h1>
                        <p>
                            Proporcionar aos usuários a melhor experiência com as tecnologias e produtos oferecidos pela 3Layers, com o objetivo de facilitar e contribuir para o desempenho e crescimento de nossos clientes e seus negócios. 
                        </p>
                    </div>
                    <div className="aboutColum">
                        <h1>Visão</h1>
                        <p>
                            Ser referência no desenvolvimento de soluções de alto impacto, fornecendo as pessoas ferramentas tecnológicas para solucionar seus problemas, ou proporcionar facilidade e agilidade nas suas tarefas diárias.
                        </p>
                    </div>
                    <div className="aboutColum">
                        <h1>Valores</h1>
                        <p>
                            Integridade, honestidade e confiança.<br /> Respeito pelos clientes e colaboradores.<br /> Sigilo e segurança com as informações dos projetos.<br /> Foco e objetividade no desenvolvimento dos projetos.<br /> Bem-estar e crescimento dos colaboradores e clientes.
                        </p>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Sobre