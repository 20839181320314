import React, { Component } from 'react'
import Bounce from 'react-reveal/Bounce'
import { Link, NavLink } from 'react-router-dom'

/* Importando estilos css */
import './sectionMobile.css'


/* Importando imagenes */
import img1 from './pics/mobileImg.svg'
import BudgetForm from '../../componentes/budgetForm'

/* Componente encargado de cargar las imagenes */
function Imagen({ src }) {
    return (
        <img
            src={src}
            alt='mobile-img'
            className='mobileImg'
        />
    )
}

class SectionMobile extends Component {
    state = {
        show: false
      };
      showModal = e => {
        this.setState({
          show: !this.state.show
        });
      };
    render() {
        return (
            <section className='mobile'>
                <span className='mobileBg'></span>
                <div className='mobileContainer'>
                    <Bounce right cascade>
                        <div className='mobileLeft'>
                            <h1>A tecnologia na palma da sua mão!</h1>
                            <p>
                                Desenvolvemos aplicativos móveis para tornar seu dia a dia mais fácil. Trazendo todas as informações e controles de sua empresa na sua mão.
                            </p>
                            <NavLink activeClass='active' to='/contato'>
                                Solicite um orçamento
                            </NavLink>
                        </div>
                        <div className='mobileRight'>
                            <Imagen src={img1} />
                        </div>
                    </Bounce>
                </div>
            </section>
        )
    }
}

export default SectionMobile