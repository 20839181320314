import React from 'react'

import './errorPage.css'

function ErrorPage() {
    return(
        <>
            teste ErrorPAge
        </>
    )
}

export default ErrorPage