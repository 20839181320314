import React from 'react'

import { FaChevronDown } from  'react-icons/fa'
import { FaMoneyCheck } from 'react-icons/fa'
import { FaAddressCard } from 'react-icons/fa'
import { FaCogs } from 'react-icons/fa'
import { FaCubes } from 'react-icons/fa'
import { FaServer } from 'react-icons/fa'
import { FaStoreAlt } from 'react-icons/fa'
import { FaSitemap } from 'react-icons/fa'
import { FaMapMarkerAlt } from 'react-icons/fa'
import { FaMobileAlt } from 'react-icons/fa'
import { FaEdit } from 'react-icons/fa'

import './servicos.css'

import ServicosVideo from './pics/servicos.mp4'

/* Componente encargado de cargar los Videos */
function VideoComp ({ src }) {
    return (
        <video autoPlay loop>
            <source
                src={ src }
                type='video/mp4'
                className="videoHeader"
            />
        </video>
    )
}

function Servicos() {
    return(
        <section>
            <div className='servVideo'>
                <VideoComp src={ ServicosVideo } />
                <h1>Serviços que temos a sua disposição!</h1>
            </div>
            <div className="servContainer">
                <div className='servPanel'>
                    <h1>Software</h1>
                    <p>Desenvolvemos softwares customizados para atender especificamente a demanda do cliente.</p>
                    <ul>
                        <li>
                            <div className='servFace1'>
                                <FaMoneyCheck className='iconFont' />
                                <p>Gestão Empresarial</p>
                                <FaChevronDown className='arrowIcon' />
                            </div>
                            <div className='servFace2'>
                                <hr />
                                <p>Gerenciamento total de sua empresa, cadastros, emissão de nota físcal, relatórios, e outros.</p>
                            </div>
                        </li>
                        <li>
                            <div className='servFace1'>
                                <FaAddressCard className='iconFont' />
                                <p>Gestão de Vendas Online</p>
                                <FaChevronDown className='arrowIcon' />
                            </div>
                            <div className='servFace2'>
                                <hr />
                                <p>Controle e administre suas vendas online em uma plataforma customizada a sua necessidade.</p>
                            </div>
                        </li>
                        <li>
                            <div className='servFace1'>
                                <FaCogs className='iconFont' />
                                <p>Automação</p>
                                <FaChevronDown className='arrowIcon' />
                            </div>
                            <div className='servFace2'>
                                <hr />
                                <p>Automatização de processos para reduzir custos e aumentar a produtividade de seu negócio.</p>
                            </div>
                        </li>
                        <li>
                            <div className='servFace1'>
                                <FaCubes className='iconFont' />
                                <p>Sistema PDV</p>
                                <FaChevronDown className='arrowIcon' />
                            </div>
                            <div className='servFace2'>
                                <hr />
                                <p>Informações integradas em um sistema completo para agilizar e facilitar suas vendas.</p>
                            </div>
                        </li>
                    </ul>
                </div>
                <div className='servPanel'>
                    <h1>Web</h1>
                    <p>Layouts desenvolvidos para proporcionar um visual moderno e atraente para seu negócio.</p>
                    <ul>
                        <li>
                            <div className='servFace1'>
                                <FaServer className='iconFont' />
                                <p>Hospedagem</p>
                                <FaChevronDown className='arrowIcon' />
                            </div>
                            <div className='servFace2'>
                                <hr />
                                <p>Hospedagem robusta e customizada a necessidade de seu site ou loja virtual.</p>
                            </div>
                        </li>
                        <li>
                            <div className='servFace1'>
                                <FaStoreAlt className='iconFont' />
                                <p>E-Commerce</p>
                                <FaChevronDown className='arrowIcon' />
                            </div>
                            <div className='servFace2'>
                                <hr />
                                <p>Com o 3LStore você expande suas vendas e proporciona um melhor atendimento aos seus clientes.</p>
                            </div>
                        </li>
                        <li>
                            <div className='servFace1'>
                                <FaSitemap className='iconFont' />
                                <p>Websites</p>
                                <FaChevronDown className='arrowIcon' />
                            </div>
                            <div className='servFace2'>
                                <hr />
                                <p>Websites modernos para que sua marca ou negócio tenha a visibilidade que merece.</p>
                            </div>
                        </li>
                        <li>
                            <div className='servFace1'>
                                <FaMapMarkerAlt className='iconFont' />
                                <p>Posicionamento web</p>
                                <FaChevronDown className='arrowIcon' />
                            </div>
                            <div className='servFace2'>
                                <hr />
                                <p>Com as ferramentas e técnicas certas, ajudamos a destacar o posicionamento de sua marca ou negócio na web.</p>
                            </div>
                        </li>
                    </ul>
                </div>
                <div className='servPanel'>
                    <h1>Aplicativos Móveis</h1>
                    <p>Desenvolvemos aplicativos móveis para levar sua empresa a outro nível, seus clientes com mais acessibilidade ao seus produtos e sua empresa com mais vendas.</p>
                    <ul>
                        <li>
                            <div className='servFace1'>
                                <FaMobileAlt className='iconFont' />
                                <p>Plataforma Android</p>
                                <FaChevronDown className='arrowIcon' />
                            </div>
                            <div className='servFace2'>
                                <hr />
                                <p>Aplicativos para Android.</p>
                            </div>
                        </li>
                        <li>
                            <div className='servFace1'>
                                <FaMobileAlt className='iconFont' />
                                <p>Plataforma IOS</p>
                                <FaChevronDown className='arrowIcon' />
                            </div>
                            <div className='servFace2'>
                                <hr />
                                <p>Aplicativos para IOS.</p>
                            </div>
                        </li>
                    </ul>
                </div>
                <div className='servPanel'>
                    <h1>Design Gráfico</h1>
                    <p>Técnicas e ferramentas de alta capacidade para proporcionar as melhores soluções em desenhos gráficos.</p>
                    <ul>
                        <li>
                            <div className='servFace1'>
                                <FaEdit className='iconFont' />
                                <p>Identidade Visual</p>
                                <FaChevronDown className='arrowIcon' />
                            </div>
                            <div className='servFace2'>
                                <hr />
                                <p>Projetamos e criamos a identidade visual de sua empresa.</p>
                            </div>
                        </li>
                        <li>
                            <div className='servFace1'>
                                <FaEdit className='iconFont' />
                                <p>Criação de logomarcas</p>
                                <FaChevronDown className='arrowIcon' />
                            </div>
                            <div className='servFace2'>
                                <hr />
                                <p>Desenvolvemos a logomarca ideal para sua empresa ou negócio marca o público alvo.</p>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </section>
    )
}

export default Servicos